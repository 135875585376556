import { useContext } from 'react';
import PropsContext from '../../contexts/PropsContext';

const useWhiteLabelConfig = () => {
  const context = useContext(PropsContext);

  const whitelabel =
    context.initialState.whitelabel.whiteLabelInfo.data ||
    JSON.parse(localStorage.getItem('config') || '{}');

  return whitelabel;
};

export default useWhiteLabelConfig;
