import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Row, Col } from 'react-bootstrap';
import ErrorBoundary from '@esolidar/toolkit/build/components/errorBoundary';
import HeaderCompany from '../header/HeaderCompany';
import Feed from './Feed';
import CreatePost from './CreatePost';
import changeLangFromUrl from '../../../shared/utils/changeLangFromUrl';
import PropsContext from '../../../contexts/PropsContext';
import isExceptionPages from '../../../shared/utils/isExceptionPages';

const FeedPage = () => {
  const propsContext = useContext(PropsContext);
  const router = useRouter();
  const isLoggedIn = !!propsContext.token;
  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';
  const isExceptionPage = isExceptionPages(router.pathname);

  changeLangFromUrl(lang);

  return (
    <div>
      {(!isLoggedIn || isExceptionPage) && <HeaderCompany />}
      <div className="feed-page">
        <Row>
          <Col xs={12} lg={12} className="mx-auto" style={{ maxWidth: '668px' }}>
            <Row>
              <Col>
                <ErrorBoundary>
                  <CreatePost />
                </ErrorBoundary>
                <ErrorBoundary>
                  <Feed />
                </ErrorBoundary>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeedPage;
