/* global Echo */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@esolidar/feed/build/views/list/List';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import {
  feedGet,
  feedLovePost,
  feedCommentPost,
  postCommentResponse,
  getCommentResponses,
  deleteResponse,
  deleteComment,
  deletePost,
  feedCommentGet,
  getPostId,
  getPublicFeed,
  getPublicReplies,
  getFeedPublicComment,
  feedWebScrapter,
  feedUploadGallery,
  feedDeleteImageGallery,
  editPost,
} from '../../../store/actions/feed';
import { toggleLoginModal } from '../../../store/actions/authUser';
import { showAlert } from '../../../store/actions/alert';
import { RootState } from '../../../store/reducers';
import useUser from '../../../shared/hooks/useUser';
import useCompany from '../../../shared/hooks/useCompany';
import useWhiteLabelConfig from '../../../shared/hooks/useWhiteLabelConfig';

const Feed = (): JSX.Element => {
  const [commentCreated, setCommentCreated] = useState(null);
  const [commentResponse, setCommentResponse] = useState(null);
  const [postCreated, setPostCreated] = useState(null);
  const dispatch = useDispatch();
  const feedList = useSelector((state: RootState) => state.feed.feed);
  const replyComment = useSelector((state: RootState) => state.feed.replyComment);
  const getNewResponse = useSelector((state: RootState) => state.feed.getNewResponse);
  const getResponse = useSelector((state: RootState) => state.feed.getResponse);
  const newPost = useSelector((state: RootState) => state.feed.newPost);
  const love = useSelector((state: RootState) => state.feed.love);
  const deletedResponse = useSelector((state: RootState) => state.feed.deleteResponse);
  const deletedComment = useSelector((state: RootState) => state.feed.deletedComment);
  const deletedPost = useSelector((state: RootState) => state.feed.deletedPost);
  const comments = useSelector((state: RootState) => state.feed.comments);
  const postById = useSelector((state: RootState) => state.feed.postById);
  const publicFeed = useSelector((state: RootState) => state.feed.feed);

  const scraperResponse = useSelector((store: RootState) => store.feed.scrapter);
  const imagesResponse = useSelector((store: RootState) => store.feed.gallery);
  const feedPostResponse = useSelector((store: RootState) => store.feed.newPost);
  const updatePost = useSelector((store: RootState) => store.feed.updatePost);

  const user = useUser();
  const company = useCompany();
  const whiteLabelConfig = useWhiteLabelConfig();
  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';

  useEffect(() => {
    if (company?.id) {
      // @ts-ignore
      Echo.private(`App.Company.${company.id}`).listen('CommentCreated', data => {
        setCommentCreated(data);
      });

      // @ts-ignore
      Echo.private('App.Company').listen('CommentCreated', data => {
        setCommentCreated(data);
      });

      // @ts-ignore
      Echo.private(`App.Company.${company.id}`).listen('CommentResponseCreated', data => {
        setCommentResponse(data);
      });

      // @ts-ignore
      Echo.private('App.Company').listen('CommentResponseCreated', data => {
        setCommentResponse(data);
      });

      // @ts-ignore
      Echo.private(`App.Company.${company.id}`).listen('PostCreated', data => {
        setPostCreated(data.post_id);
      });
    }
  }, [company]);

  const props = {
    pusher: {
      commentCreated,
      commentResponse,
      postCreated,
    },
    actions: {
      showAlert: alertBox => {
        dispatch(showAlert(alertBox));
      },
      feedGet: (companyId, page, perPage) => {
        dispatch(feedGet(companyId, page, perPage));
      },
      addComment: (companyId, postId, data) => {
        dispatch(
          feedCommentPost(companyId, postId, {
            text: data.text,
            as_company: data.asCompany,
            as_company_id: data.asCompanyId,
          })
        );
      },
      addLove: (companyId, postId, data) => {
        dispatch(feedLovePost(companyId, postId, data));
      },
      postCommentResponse: (companyId, postId, commentId, data) => {
        dispatch(
          postCommentResponse(companyId, postId, commentId, {
            text: data.text,
            as_company: data.asCompany,
            as_company_id: data.asCompanyId,
          })
        );
      },
      getCommentResponses: (companyId, postId, commentId, page, perPage) => {
        dispatch(getCommentResponses(companyId, postId, commentId, page, perPage));
      },
      deleteResponse: (companyId, postId, commentId, responseId) => {
        dispatch(deleteResponse(companyId, postId, commentId, responseId));
      },
      deleteComment: (companyId, postId, commentId) => {
        dispatch(deleteComment(companyId, postId, commentId));
      },
      deletePost: (companyId, postId) => {
        dispatch(deletePost(companyId, postId));
      },
      feedCommentGet: (companyId, postId, page, perPage) => {
        dispatch(feedCommentGet(companyId, postId, page, perPage));
      },
      getPostId: (companyId, postId) => {
        dispatch(getPostId(companyId, postId));
      },
      getPublicFeed: (companyId, activePage, postsPerPage) => {
        dispatch(getPublicFeed(companyId, activePage, postsPerPage));
      },
      getPublicReplies: (postId, commentId, page, perPage) => {
        dispatch(getPublicReplies(postId, commentId, page, perPage));
      },
      getFeedPublicComment: (postId, page, perPage) => {
        dispatch(getFeedPublicComment(postId, page, perPage));
      },
      editPost: (companyId, postId, data) => {
        dispatch(editPost(companyId, postId, data));
      },
      feedWebScrapter: (companyId, url) => dispatch(feedWebScrapter(companyId, url)),
      feedUploadGallery: (companyId, file) => dispatch(feedUploadGallery(companyId, file)),
      feedDeleteImageGallery: (companyId, id) => dispatch(feedDeleteImageGallery(companyId, id)),
    },
    reducers: {
      company,
      whiteLabelConfig,
      user: isEmpty(user) ? null : user,
      feedList: feedList.data ? feedList.data : null,
      publicFeed: publicFeed.data ? publicFeed.data : null,
      replyComment: replyComment.data ? replyComment.data : {},
      getResponse: getResponse.data ? getResponse.data : {},
      getNewResponse: getNewResponse.data ? getNewResponse.data : {},
      newPost: newPost.data ? newPost.data : null,
      love: love.data ? love : null,
      deletedResponse: deletedResponse.data ? deletedResponse.data : null,
      deletedComment: deletedComment.data ? deletedComment.data : null,
      deletedPost: deletedPost.data ? deletedPost.data : null,
      comments: comments.data ? comments.data : null,
      postById: postById.code === 200 ? postById.data : null,
      updatePost: updatePost.data,
      feedPostResponse,
      scraperResponse: scraperResponse.code === 200 ? scraperResponse.data : null,
      imagesResponse: imagesResponse.data,
    },
    cdnStaticUrl: process.env.NEXT_PUBLIC_cdn_static_url,
    companyId: company?.id,
    loginAction: () => dispatch(toggleLoginModal({ isOpen: true, nextUrl: `/${lang}/feed` })),
  };

  return <List {...props} />;
};

export default Feed;
