import axios from 'axios';
import { uploadFeedGallery } from '../../../shared/utils/formData';
import {
  FETCH_FEED,
  GET_PUBLIC_FEED,
  FETCH_OTHER_COMPANIES_FEED,
  FETCH_POST_COMMENT,
  FETCH_POST,
  UPDATE_POST,
  FETCH_COMMENT_POST,
  DELETE_POST,
  DELETE_COMMENT,
  FETCH_RESPONSE_COMMENT,
  POST_RESPONSE_COMMENT,
  UPDATE_COMMENT,
  DELETE_RESPONSE,
  UPDATE_RESPONSE,
  FETCH_LOVE,
  FETCH_SCRAPTER,
  UPLOAD_GALLERY,
  DELETE_IMAGE_GALLERY,
  FETCH_POST_BY_ID,
  FETCH_POST_PUBLIC_COMMENT,
  FETCH_PUBLIC_RESPONSE_COMMENT,
  FETCH_NEW_POST_BY_ID,
  FETCH_PUBLIC_POST_COMMENT,
  FETCH_PUBLIC_REPLIES,
} from '..';

import ROOT_URL from '../../../shared/constants/apiUrl';

export function feedGet(companyId, page, perPage) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_FEED,
    payload: request,
  };
}

export function getPublicFeed(companyId, page, perPage) {
  const request = axios.get(
    `${ROOT_URL}posts/public?company_id=${companyId}&page=${page}&per_page=${perPage}`
  );

  return {
    type: GET_PUBLIC_FEED,
    payload: request,
  };
}

export function otherCompaniesfeedGet(companyId, sort = 'created_at,asc', page = '1') {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts/public?sort=${sort}&page=${page}`
  );

  return {
    type: FETCH_OTHER_COMPANIES_FEED,
    payload: request,
  };
}

export function feedCommentGet(companyId, postId, page, perPage) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_POST_COMMENT,
    payload: request,
  };
}

export function getFeedPublicComment(postId, page, perPage) {
  const request = axios.get(
    `${ROOT_URL}posts/${postId}/comments/public?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_PUBLIC_POST_COMMENT,
    payload: request,
  };
}

export function feedPublicCommentGet(companyId, postId, page, perPage) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/public/comments?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_POST_PUBLIC_COMMENT,
    payload: request,
  };
}

export function feedPost(companyId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/posts`, data);

  return {
    type: FETCH_POST,
    payload: request,
  };
}

export function feedCommentPost(companyId, postId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/posts/${postId}/comments`, data);

  return {
    type: FETCH_COMMENT_POST,
    payload: request,
  };
}

export function deletePost(companyId, postId) {
  const request = axios.delete(
    `${ROOT_URL}companies/${companyId}/posts/${postId}?post-id=${postId}`
  );

  return {
    type: DELETE_POST,
    payload: request,
  };
}

export function deleteComment(companyId, postId, commentId) {
  const request = axios.delete(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}?comment-id=${commentId}`
  );

  return {
    type: DELETE_COMMENT,
    payload: request,
  };
}

export function editComment(companyId, postId, commentId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}`,
    data
  );

  return {
    type: UPDATE_COMMENT,
    payload: request,
  };
}

export function editPost(companyId, postId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/posts/${postId}`, data);

  return {
    type: UPDATE_POST,
    payload: request,
  };
}

export function getCommentResponses(companyId, postId, commentId, page, perPage = 3) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}/responses?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_RESPONSE_COMMENT,
    payload: request,
  };
}

export function getPublicReplies(postId, commentId, page, perPage = 3) {
  const request = axios.get(
    `${ROOT_URL}posts/${postId}/comments/${commentId}/responses/public?page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_PUBLIC_REPLIES,
    payload: request,
  };
}

export function getPublicCommentResponses(companyId, postId, commentId, page, perPage = 3) {
  const request = axios.get(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/public/comments/${commentId}/responses?per_page=3&page=${page}&per_page=${perPage}`
  );

  return {
    type: FETCH_PUBLIC_RESPONSE_COMMENT,
    payload: request,
  };
}

export function postCommentResponse(companyId, postId, commentId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}/responses`,
    data
  );

  return {
    type: POST_RESPONSE_COMMENT,
    payload: request,
  };
}

export function deleteResponse(companyId, postId, commentId, responseId) {
  const request = axios.delete(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}/responses/${responseId}?response-id=${responseId}`
  );

  return {
    type: DELETE_RESPONSE,
    payload: request,
  };
}

export function editResponse(companyId, postId, commentId, responseId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/comments/${commentId}/responses/${responseId}`,
    data
  );

  return {
    type: UPDATE_RESPONSE,
    payload: request,
  };
}

export function feedLovePost(companyId, postId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/posts/${postId}/loves?post-id=${postId}`,
    data
  );

  return {
    type: FETCH_LOVE,
    payload: request,
  };
}

export function feedWebScrapter(companyId, data) {
  const request = axios.post(`${ROOT_URL}companies/${companyId}/posts/scraper`, { url: data });

  return {
    type: FETCH_SCRAPTER,
    payload: request,
  };
}

export function feedUploadGallery(companyId, data) {
  const request = axios.post(
    `${ROOT_URL}companies/${companyId}/posts/images`,
    uploadFeedGallery(data)
  );

  return {
    type: UPLOAD_GALLERY,
    payload: request,
  };
}

export function feedDeleteImageGallery(companyId, imageId, data) {
  const request = axios.delete(`${ROOT_URL}companies/${companyId}/posts/images/${imageId}`, data);

  return {
    type: DELETE_IMAGE_GALLERY,
    payload: request,
  };
}

export function getPostId(companyId, postId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}/posts/${postId}`);

  return {
    type: FETCH_POST_BY_ID,
    payload: request,
  };
}

export function getNewPostById(companyId, postId) {
  const request = axios.get(`${ROOT_URL}companies/${companyId}/posts/${postId}`);

  return {
    type: FETCH_NEW_POST_BY_ID,
    payload: request,
  };
}
