import { useContext } from 'react';
import HeaderCompany from '@esolidar/toolkit/build/components/headerCompany';
import PropsContext from '../../../contexts/PropsContext';

const HeaderCompanyComponent = () => {
  const context = useContext(PropsContext);
  const { company } = context.initialState.whitelabel.whiteLabelInfo.data;

  return (
    <div className="headercompany">
      <HeaderCompany company={company} cdnStaticUrl={process.env.NEXT_PUBLIC_cdn_uploads_url} />
    </div>
  );
};

export default HeaderCompanyComponent;
