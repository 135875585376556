import cookie from 'react-cookies';

export default function changeLangFromUrl(lang) {
  if (typeof window !== 'undefined') {
    const currentLang = localStorage.getItem('lang');
    cookie.save('NEXT_LOCALE', lang, { path: '/' });

    if (lang && currentLang !== lang) {
      switch (lang) {
        case 'pt':
          localStorage.setItem('lang', 'pt');
          if (currentLang !== lang) return window.location.reload();
          break;

        case 'en':
          localStorage.setItem('lang', 'en');
          if (currentLang !== lang) return window.location.reload();
          break;

        case 'br':
          localStorage.setItem('lang', 'br');
          if (currentLang !== lang) return window.location.reload();
          break;

        default:
          window.location.href = '/not-found';
      }
    }
  }
}
