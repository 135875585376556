import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewPostBox from '@esolidar/feed/build/components/newPostBox';
import isEmpty from '@esolidar/toolkit/build/utils/isEmpty';
import useCompany from '../../../shared/hooks/useCompany';
import {
  feedPost,
  feedWebScrapter,
  feedUploadGallery,
  feedDeleteImageGallery,
} from '../../../store/actions/feed';
import { toggleLoginModal } from '../../../store/actions/authUser';
import PropsContext from '../../../contexts/PropsContext';

const CreatePost = () => {
  const propsContext = useContext(PropsContext);
  const dispatch = useDispatch();
  const lang = typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en';
  const company = useCompany();

  const scraperResponse = useSelector(store => store.feed.scrapter);
  const imagesResponse = useSelector(store => store.feed.gallery);
  const feedPostResponse = useSelector(store => store.feed.newPost);
  const { user } = propsContext;
  const isLoggedIn = !!propsContext.token;

  return (
    <div style={{ maxWidth: '668px', margin: '0px auto' }}>
      <NewPostBox
        isAdmin={false}
        company={company}
        isLoggedIn={isLoggedIn}
        feedPostResponse={feedPostResponse}
        user={isEmpty(user) ? null : user}
        companyId={company?.id}
        feedPost={(companyId, state) => dispatch(feedPost(companyId, state))}
        feedWebScrapter={(companyId, url) => dispatch(feedWebScrapter(companyId, url))}
        feedUploadGallery={(companyId, file, i) => dispatch(feedUploadGallery(companyId, file, i))}
        scraper={scraperResponse.code === 200 ? scraperResponse.data : null}
        imagesResponse={imagesResponse.data}
        loginAction={() => dispatch(toggleLoginModal({ isOpen: true, nextUrl: `/${lang}/feed` }))}
        deleteImages={(companyId, id) => dispatch(feedDeleteImageGallery(companyId, id))}
      />
    </div>
  );
};

export default CreatePost;
